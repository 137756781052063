import JSZip from 'jszip'
import axios from 'axios'

const getConfig = () => {
	const localToken = localStorage.getItem('setCHGG')
	const token = localToken ? JSON.parse(localToken) : ''
	return {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'arraybuffer',
	}
}

export const downloadAzureFile = async (url) => {
	const config = getConfig()
	return axios.get(`api/azure?url=${url}`, config)
}

export const slowDownloadMultiAzureFiles = async (urls, successCb, errorCb) => {
	for (let i = 0; i < urls.length; i++) {
		const urlArr = urls[i]?.split('+')
		let [_, __, blobName] = String(urlArr[0]).split('/')
		blobName = urlArr[1] + '-' + blobName
		try {
			const { data } = await downloadAzureFile(urlArr[0])
			successCb({ blobName, data })
		} catch (error) {
			errorCb({ blobName, error })
		}
	}
}
export const fastDownloadMultiAzureFiles = async (urls, successCb, errorCb) => {
	const promises = urls.map(async (url) => {
		const [_, __, blobName] = String(url).split('/')
		try {
			const { data } = await downloadAzureFile(url)
			successCb({ blobName, data })
		} catch (error) {
			errorCb({ blobName, error })
		}
	})
	await Promise.all(promises)
}

export const slowDownloadAndZipPDFs = async (urls, zipFileName, successCb, errorCb) => {
	const zip = new JSZip()

	const successFunc = ({ blobName, data }) => {
		successCb()
		zip.file(blobName, data)
	}
	const errorFunc = ({ blobName, error }) => {
		console.log(error)
		errorCb(blobName)
	}
	await slowDownloadMultiAzureFiles(urls, successFunc, errorFunc)

	const zipContent = await zip.generateAsync({ type: 'blob' })

	const downloadLink = document.createElement('a')
	downloadLink.href = URL.createObjectURL(zipContent)
	downloadLink.download = `${zipFileName}.zip`
	downloadLink.click()
}

export const fastDownloadAndZipPDFs = async (urls, zipFileName, successCb, errorCb) => {
	const zip = new JSZip()
	const successFunc = ({ blobName, data }) => {
		successCb()
		zip.file(blobName, data)
	}
	const errorFunc = ({ blobName, error }) => {
		console.log(error)
		errorCb(blobName)
	}
	await fastDownloadMultiAzureFiles(urls, successFunc, errorFunc)

	const zipContent = await zip.generateAsync({ type: 'blob' })

	const downloadLink = document.createElement('a')
	downloadLink.href = URL.createObjectURL(zipContent)
	downloadLink.download = `${zipFileName}.zip`
	downloadLink.click()
}
